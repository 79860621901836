import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text, useBreakpointValue, Grid } from '@chakra-ui/react';
import { AnimationButton } from '../button';

const CtaBottom = ({ data, children, ...rest }) => {
  const buttonSize = useBreakpointValue({ base: 'sm', lg: 'lg' });

  return (
    <Grid w="100%" gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }} {...rest}>
      {data &&
        data.map((item) => (
          <Flex
            key={item.bg}
            flex="1"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            bg={item.bg}
            borderRadius="30px"
            mb={{ base: 'space.s', md: 'space.xl' }}
            p={{ base: 'space.xl', md: 'space.3xl' }}
            _first={{
              mr: { base: '0', lg: 'space.xl' },
            }}
          >
            <Box>
              <Text
                color="font.inverse"
                fontWeight="weight.m"
                fontSize={{ base: '32px', lg: '49px' }}
                lineHeight="120%"
                mb="space.2xs"
                whiteSpace={{ base: 'normal', sm: 'nowrap', lg: 'normal' }}
              >
                {item.title}
              </Text>
              <Text
                color="font.inverse"
                fontSize={{ base: '14px', lg: '20px' }}
                lineHeight={{ base: '150%', lg: '140%' }}
                mb={{ base: 'space.xl', lg: '56px' }}
              >
                {item.content}
              </Text>
            </Box>
            <AnimationButton
              variant="white"
              size={buttonSize}
              flex="0 0 auto"
              onClick={item.onClick}
            >
              {item.buttonText}
            </AnimationButton>
          </Flex>
        ))}
      <Box
        gridColumn={{
          base: '1 / 2',
          lg: '1 / 3',
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

CtaBottom.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      iconWebp: PropTypes.node,
      title: PropTypes.string,
      content: PropTypes.string,
      buttonText: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};
CtaBottom.defaultProps = {
  children: null,
  data: [],
};

export default CtaBottom;
