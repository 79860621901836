import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Box } from '@chakra-ui/react';
import I18n from '../I18n';
import Button from '../button/index';
import walletMobileCard from '../../../static/new_images/ecosystemPage/brandcard/m-brandcard-wallet.png';
import walletMobileCardWebp from '../../../static/new_images/ecosystemPage/brandcard/m-brandcard-wallet.webp';
import walletCard from '../../../static/new_images/ecosystemPage/brandcard/brandcard-wallet.png';
import walletCardWebp from '../../../static/new_images/ecosystemPage/brandcard/brandcard-wallet.webp';
import sdkCard from '../../../static/new_images/ecosystemPage/brandcard/sdk.png';
import sdkCardWebp from '../../../static/new_images/ecosystemPage/brandcard/sdk.webp';
import bayCard from '../../../static/new_images/ecosystemPage/brandcard/bay.png';
import bayCardWebp from '../../../static/new_images/ecosystemPage/brandcard/bay.webp';
import flowstakingCard from '../../../static/new_images/ecosystemPage/brandcard/flow.png';
import flowstakingCardWebp from '../../../static/new_images/ecosystemPage/brandcard/flow.webp';
import swapCard from '../../../static/new_images/ecosystemPage/brandcard/swap.png';
import swapCardWebp from '../../../static/new_images/ecosystemPage/brandcard/swap.webp';
import { useDevice } from '../../hooks/useDevice';
import download from '../../utils/download';
import gtagEvent from '../../utils/gtag';

const firstData = [
  {
    title: <I18n id="blocto.ecosystem.brand.card1.title" />,
    content: <I18n id="blocto.ecosystem.brand.card1.content" />,
    buttonText: <I18n id="blocto.ecosystem.brand.card1.button" />,
    buttonLink: '/download',
    bgUrl: walletCard,
    bgUrlWebp: walletCardWebp,
    bgUrlMobile: walletMobileCard,
    bgUrlMobileWebp: walletMobileCardWebp,
    bgColor: '#EBFAFA',
    fontColor: 'font.highlight',
    w: { base: '296px', md: '588px', lg: '696px' },
    h: { base: '160px', md: '320px' },
    bgSize: { base: '100%', md: '100%' },
    bgPosition: { base: 'bottom', md: 'bottom' },
  },
  {
    title: <I18n id="blocto.ecosystem.brand.card2.title" />,
    content: <I18n id="blocto.ecosystem.brand.card2.content" />,
    buttonText: <I18n id="blocto.ecosystem.brand.card2.button" />,
    buttonLink: '/sdk',
    bgUrl: sdkCard,
    bgUrlWebp: sdkCardWebp,
    bgUrlMobile: sdkCard,
    bgUrlMobileWebp: sdkCardWebp,
    bgColor: 'font.highlight',
    fontColor: 'font.inverse',
    bgSize: { base: '40px', md: '80px' },
    bgPosition: { base: '240px 110px', md: '478px 240px', lg: '370px 240px' },
    w: { base: '296px', md: '588px', lg: '480px' },
    h: { base: '160px', md: '320px' },
    gtag: {
      event: 'bloctoweb_click_sdkpage',
      properties: {
        location: 'main_ecosystem_block',
      },
    },
  },
  {
    title: <I18n id="blocto.ecosystem.brand.card3.title" />,
    content: <I18n id="blocto.ecosystem.brand.card3.content" />,
    buttonText: <I18n id="blocto.ecosystem.brand.card3.button" />,
    buttonLink: 'https://bay.blocto.app/',
    bgUrl: bayCard,
    bgUrlWebp: bayCardWebp,
    bgUrlMobile: bayCard,
    bgUrlMobileWebp: bayCardWebp,
    bgColor: 'icon.text',
    fontColor: 'font.inverse',
    bgSize: { base: '40px', md: '75px' },
    bgPosition: { base: '140px 110px', md: '275px 240px' },
    w: { base: '194px', md: '384px' },
    h: { base: '160px', md: '320px' },
    gtag: {
      event: 'bloctoweb_click_bloctobay',
      properties: {
        location: 'main_ecosystem_block',
      },
    },
  },
  {
    title: <I18n id="blocto.ecosystem.brand.card4.title" />,
    content: <I18n id="blocto.ecosystem.brand.card4.content" />,
    buttonText: <I18n id="blocto.ecosystem.brand.card4.button" />,
    buttonLink: 'https://blocto.app.link/flow-staking',
    bgUrl: flowstakingCard,
    bgUrlWebp: flowstakingCardWebp,
    bgUrlMobile: flowstakingCard,
    bgUrlMobileWebp: flowstakingCardWebp,
    bgColor: '#EBFAFA',
    fontColor: 'font.highlight',
    bgSize: { base: '100px', md: '215px' },
    bgPosition: { base: '74px 35px', md: '100px center' },
    w: { base: '194px', md: '384px' },
    h: { base: '160px', md: '320px' },
    gtag: {
      event: 'bloctoweb_click_link',
      properties: {
        location: 'main_ecosystem_block',
        topic_title: 'Stake your FLOW token and earn handsome rewards',
      },
    },
  },
  {
    title: <I18n id="blocto.ecosystem.brand.card5.title" />,
    content: <I18n id="blocto.ecosystem.brand.card5.content" />,
    buttonText: <I18n id="blocto.ecosystem.brand.card5.button" />,
    buttonLink: 'https://swap.blocto.app/',
    bgUrl: swapCard,
    bgUrlWebp: swapCardWebp,
    bgUrlMobile: swapCard,
    bgUrlMobileWebp: swapCardWebp,
    bgColor: '#32BEFF',
    fontColor: 'font.inverse',
    bgSize: { base: '40px', md: '80px' },
    bgPosition: { base: '140px 110px', md: '280px 220px' },
    w: { base: '194px', md: '384px' },
    h: { base: '160px', md: '320px' },
    gtag: {
      event: 'bloctoweb_click_bloctoswap',
      properties: {
        location: 'main_ecosystem_block',
      },
    },
  },
];

const BrandCard = ({ isSupportWebp }) => {
  const { isMOBILE } = useDevice();

  const toPageHandler = (url, gtag, onMobile) => {
    if (!isMOBILE && onMobile) {
      return;
    }
    if (url === '/download') {
      download('main_ecosystem_block');
    } else {
      const properties = { ...gtag.properties };
      if (url && url.startsWith('http')) {
        properties.link_url = url;
        properties.link_domain = new URL(url).host;
      }
      gtagEvent(gtag.event, properties);
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <Flex
        justify="center"
        wrap="wrap"
        w="100%"
        mt={{ base: '68px', md: '132px' }}
        mb={{ base: '44px', md: '64px' }}
      >
        <Text
          textAlign="center"
          w="100%"
          color="icon.text"
          fontSize={{ base: '25px' }}
          lineHeight={{ base: '30px' }}
          fontWeight={600}
          maxW={{ base: '305px' }}
          sx={{
            '@media screen and (min-width: 768px)': {
              maxW: '600px',
              fontSize: '51px',
              lineHeight: '64px',
            },
          }}
        >
          <I18n id="blocto.ecosystem.brand.title" />
        </Text>
      </Flex>
      <Flex
        w="100%"
        wrap="wrap"
        bgSize="100% 100%"
        bgRepeat="no-repeat"
        borderRadius="20px"
        mb={{ base: '8px', sm: '23px' }}
        pl={{ base: '20px', md: '64px', lg: '0' }}
        maxW={{ md: '1200px' }}
        m={{ md: '0 auto' }}
        justify={{ base: 'center', md: 'space-between' }}
      >
        <Flex
          width={{ base: '100%' }}
          justify={{ base: 'flex-start', sm: 'center', md: 'flex-start', lg: 'center' }}
        >
          <Box overflowX="auto" w={{ base: '620px', md: '100%' }} maxW={{ md: '1200px' }}>
            <Flex
              justify={{ base: 'flex-start', md: 'space-between' }}
              wrap="wrap"
              w={{ base: '620px', md: '1200px' }}
              mb={{ base: '8px' }}
            >
              {firstData.map((item, idx) => (
                <Box
                  key={item.title}
                  bgImage={{
                    base: isSupportWebp ? item.bgUrlMobileWebp : item.bgUrlMobile,
                    md: isSupportWebp ? item.bgUrlWebp : item.bgUrl,
                  }}
                  bgColor={item.bgColor}
                  bgRepeat="no-repeat"
                  borderRadius="24px"
                  w={item.w}
                  h={item.h}
                  p={{ base: '16px', md: '32px' }}
                  bgSize={item.bgSize}
                  bgPosition={item.bgPosition}
                  m={{
                    base: '8px 8px 0 0',
                    md: [0, 4].includes(idx) ? '24px 24px 0 0' : '24px 24px 0 0',
                  }}
                  mr={{ md: idx === 0 ? '24px' : '0' }}
                  position="relative"
                  onClick={() => toPageHandler(item.buttonLink, item.gtag, true)}
                >
                  <Text
                    w="100%"
                    color={item.fontColor}
                    fontWeight={{ base: 500, md: 600 }}
                    fontSize={{ base: '14px', sm: '16px', md: '25px' }}
                  >
                    {item.title}
                  </Text>
                  <Text
                    mt={{ base: '17px' }}
                    w={{ base: '165px', md: '340px' }}
                    lineHeight={{ base: '21px', md: '38px' }}
                    color={item.fontColor}
                    fontWeight={600}
                    fontSize={{ base: '18px', sm: '20px', md: '32px' }}
                  >
                    {item.content}
                  </Text>
                  <Box
                    display={{ base: 'none', md: 'block' }}
                    mt={{ md: '94px' }}
                    w={{ md: '146px' }}
                    h={{ md: '46px' }}
                    position={{ md: 'absolute' }}
                    bottom={{ md: '25px' }}
                  >
                    <Button
                      background="transparent"
                      color={item.fontColor}
                      borderColor={item.fontColor}
                      border="1px solid"
                      _hover={{ background: 'transparent' }}
                      onClick={() => {
                        toPageHandler(item.buttonLink, item.gtag, false);
                      }}
                    >
                      {item.buttonText}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

BrandCard.propTypes = {
  isSupportWebp: PropTypes.bool,
};

BrandCard.defaultProps = {
  isSupportWebp: true,
};
export default BrandCard;
