import { useState, useEffect } from "react";

export const sizes = {
    tablet: 769,
    laptop: 992,
    desktop: 1440,
};

export function useDevice(breakpoints = sizes) {
    if (typeof window === "undefined") {
        return {};
    }
    const isMOBILE = "isMOBILE";
    const isTABLET = "isTABLET";
    const isLAPTOP = "isLAPTOP";
    const isDESKTOP = "isDESKTOP";

    const onLoadDevice = () => {
        if (window.innerWidth < breakpoints.tablet) {
            return isMOBILE;
        }
        if (window.innerWidth < breakpoints.laptop) {
            return isTABLET
        }
        if (window.innerWidth < breakpoints.desktop) {
            return isLAPTOP
        }
        return isDESKTOP;
    };

    const [device, setDevice] = useState(onLoadDevice());



    const onResizeDevice = () => {
        if (window.innerWidth < breakpoints.tablet) {
            setDevice(isMOBILE)
        } else if (window.innerWidth < breakpoints.laptop) {
            setDevice(isTABLET)
        } else if (window.innerWidth < breakpoints.desktop) {
            setDevice(isLAPTOP)
        } else {
            setDevice(isDESKTOP)
        }
    };

    useEffect(() => {
        window.addEventListener("resize", onResizeDevice);
        return () => {
            window.removeEventListener("resize", onResizeDevice);
        };
    }, []);

    return {
        isMOBILE: device === isMOBILE,
        isTABLET: device === isTABLET,
        isLAPTOP: device === isLAPTOP,
        isDESKTOP: device === isDESKTOP
    };
}