import React, { useState, useEffect } from 'react';
import { Flex, Text, Box, Image, useBreakpointValue, ScaleFade } from '@chakra-ui/react';
import I18n from '../I18n';
import tabData from './tabcard.const';
import RightArrowIcon from '../../../static/new_images/ecosystemPage/tabCard/arrow-right.png';
import downArrowIcon from '../../../static/new_images/down-arrow.png';
import gtagEvent from '../../utils/gtag';

const TabCard = () => {
  const [tabState, setTabState] = useState(0);
  const [columnNum, setcolumnNum] = useState(3);
  const [showViewMore, setShowViewMore] = useState(true);
  const openColumnNum = useBreakpointValue({ base: 3, sm: 6, lg: 9 });
  const [isFade, setIsFade] = useState(true);
  const viewMoreHandler = () => {
    setcolumnNum(columnNum + openColumnNum);
  };

  const switchTab = (index) => {
    setTabState(index);
    setIsFade(false);
    setTimeout(() => {
      setIsFade(true);
    }, 300);
  };

  useEffect(() => {
    setcolumnNum(openColumnNum);
  }, [openColumnNum]);

  useEffect(() => {
    if (tabData[tabState].children.length <= columnNum) {
      setShowViewMore(false);
    } else {
      setShowViewMore(true);
    }
  }, [columnNum, tabState]);

  return (
    <>
      <Flex
        justify="center"
        wrap="wrap"
        w={{ base: '95%', md: '100%' }}
        mt={{ base: '44px', md: '132px' }}
        mb={{ base: '69px', md: '64px' }}
        p={{ base: '0', md: '0 64px' }}
      >
        <Text
          textAlign="center"
          w="100%"
          color="icon.text"
          fontSize={{ base: '25px', md: '51px' }}
          lineHeight={{ base: '30px', md: '64px' }}
          fontWeight={600}
          maxW={{ base: '320px', md: '600px', lg: '800px' }}
        >
          <I18n id="blocto.ecosystem.tab.title" />
        </Text>
      </Flex>
      <Flex justify={{ base: 'space-around', md: 'center' }}>
        {tabData.map((tab, index) => (
          <Flex key={tab.title} maxW="800px">
            <Text
              as="span"
              fontWeight={600}
              fontSize={{ base: '16px', md: '25px' }}
              lineHeight={{ base: '22px', md: '30px' }}
              p={{ md: '16px 20px' }}
              m={{ md: '0 22px' }}
              cursor="pointer"
              color={tabState === index ? 'font.highlight' : 'icon.text'}
              borderBottom={tabState === index ? '4px solid' : 'none'}
              borderColor={tabState === index ? 'font.highlight' : 'transparent'}
              _hover={{
                color: tabState === index ? 'none' : 'font.highlight',
              }}
              onClick={() => {
                gtagEvent('bloctoweb_click_web3ecosystem', { ecosystem_title: tab.title });
                switchTab(index);
              }}
            >
              {tab.title}
            </Text>
          </Flex>
        ))}
      </Flex>
      <ScaleFade initialScale={0.9} in={isFade}>
        <Flex
          w={{ base: '95%', md: '95%' }}
          pb={{ base: '42px', lg: '26px' }}
          wrap="wrap"
          m="auto"
          mt={{ base: '44px', sm: '64px', lg: '80px' }}
          overflow="hidden"
          maxW={{ lg: '1200px' }}
          bg="#FBFBFB"
          pt={{ sm: '64px' }}
          borderRadius={{ lg: showViewMore ? '24px 24px 0 0' : '24px' }}
        >
          <Flex w="100%" wrap="wrap" m="auto" overflow="hidden" maxW={{ lg: '1200px' }}>
            {tabData[tabState].children.map((child, idx) => (
              <Flex
                key={child.icon}
                bg="#FBFBFB"
                borderRight={{
                  base: 'none',
                  sm: idx % 2 === 0 ? '1px solid #E5E5E5' : 'none',
                  lg: (idx + 1) % 3 !== 0 ? '1px solid #E5E5E5' : 'none',
                }}
                w={{ base: '100%', sm: '50%', lg: '33.3%' }}
                p={{ base: '44px' }}
                pb={{ base: '48px' }}
                wrap="wrap"
                display={{
                  base: idx + 1 <= columnNum ? 'block' : 'none',
                }}
                transition="0.5s height ease-in-out"
                position="relative"
              >
                <Box>
                  <Image w="66px" h="66px" src={child.icon} />
                </Box>
                <Text
                  w={{ base: '100%' }}
                  color="icon.text"
                  fontWeight={600}
                  fontSize={{ base: '21px' }}
                  lineHeight={{ base: '28px' }}
                  m={{ base: '12px 0' }}
                >
                  {child.title}
                </Text>
                <Text
                  w={{ base: '100%' }}
                  color="icon.text"
                  fontWeight={400}
                  fontSize={{ base: '12px' }}
                  lineHeight={{ base: '18px' }}
                  mb={{ base: '22px' }}
                >
                  {child.content}
                </Text>
                <Flex
                  justify="flex-start"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    gtagEvent('bloctoweb_click_web3ecosystemdapp', {
                      ecosystem_dapp: child.title,
                      location: 'dive_into_ecosystem_block',
                      link_url: child.link,
                      link_domain: new URL(child.link).host,
                    });
                    window.open(child.link, '_blank');
                  }}
                  position="absolute"
                  bottom="8%"
                >
                  <Image src={RightArrowIcon} w="20px" h="20px" mr="12px" />
                  <Text>{child.buttonText}</Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </ScaleFade>

      {showViewMore && (
        <Box
          pb="44px"
          bg="#FBFBFB"
          maxW={{ lg: '1200px' }}
          m={{ lg: '0 auto' }}
          borderRadius={{ lg: '0 0 24px 24px' }}
        >
          <Flex
            w="105px"
            m="0 auto"
            justify="center"
            align="center"
            wrap="wrap"
            p="6px 0"
            cursor="pointer"
            onClick={viewMoreHandler}
          >
            <Text
              w="100%"
              textAlign="center"
              color="icon.text"
              fontWeight={500}
              fontSize={{ base: '16px', sm: '18px' }}
              lineHeight={{ base: '27px', md: '21px' }}
            >
              View More
            </Text>
            <Image src={downArrowIcon} w="23px" h="11px" mt="15px" />
          </Flex>
        </Box>
      )}
    </>
  );
};

export default TabCard;
