import React from 'react';
import I18n from '../I18n';
import bayIcon from '../../../static/new_images/ecosystemPage/tabCard/bay.png';
import motoIcon from '../../../static/new_images/ecosystemPage/tabCard/moto.png';
import yahooIcon from '../../../static/new_images/ecosystemPage/tabCard/yahoo.png';
import topazIcon from '../../../static/new_images/ecosystemPage/tabCard/topaz.png';
import shouffThreeIcon from '../../../static/new_images/ecosystemPage/tabCard/souffl3.png';
import aflMintIcon from '../../../static/new_images/ecosystemPage/tabCard/aflmint.png';
import crpicoIcon from '../../../static/new_images/ecosystemPage/tabCard/crpico.png';
import emeraldCityIcon from '../../../static/new_images/ecosystemPage/tabCard/emeraldCity.png';
import swapIcon from '../../../static/new_images/ecosystemPage/tabCard/bloctoSwap.png';
import mercuryIcon from '../../../static/new_images/ecosystemPage/tabCard/mercury.png';
import flowIcon from '../../../static/new_images/ecosystemPage/tabCard/flow.png';
import pancakeSwapIcon from '../../../static/new_images/ecosystemPage/tabCard/pancakeSwap.png';
import wormholeIcon from '../../../static/new_images/ecosystemPage/tabCard/wormhole.png';
import celerIcon from '../../../static/new_images/ecosystemPage/tabCard/celer.png';
import dittoIcon from '../../../static/new_images/ecosystemPage/tabCard/ditto.png';
import tortugaIcon from '../../../static/new_images/ecosystemPage/tabCard/tortuga.png';
import ariesMarketICon from '../../../static/new_images/ecosystemPage/tabCard/ariesMarket.png';
import idoIcon from '../../../static/new_images/ecosystemPage/tabCard/ido.png';
import ftListingIcon from '../../../static/new_images/ecosystemPage/tabCard/ft-listing.png';
import nftListingIcon from '../../../static/new_images/ecosystemPage/tabCard/nft.png';
import iosSdkIcon from '../../../static/new_images/ecosystemPage/tabCard/ios-sdk.png';
import androidSdkIcon from '../../../static/new_images/ecosystemPage/tabCard/android-sdk.png';
import unitySdkIcon from '../../../static/new_images/ecosystemPage/tabCard/unity-sdk.png';
import jsSdkIcon from '../../../static/new_images/ecosystemPage/tabCard/js-sdk.png';
import flowverseIcon from '../../../static/new_images/ecosystemPage/tabCard/flowverse.png';
import flovatarIcon from '../../../static/new_images/ecosystemPage/tabCard/flovatar.png';

const tabData = [
  {
    title: <I18n id="blocto.ecosystem.tab.all" />,
    children: [
      {
        title: 'BloctoBay',
        icon: bayIcon,
        content:
          'Explore our decentralized NFT marketplace, own your faves, and display your NFT collection—all in one place.',
        buttonText: 'Discover NFTs',
        link: 'https://bay.blocto.app/',
      },
      {
        title: 'BloctoSwap',
        icon: swapIcon,
        content:
          'Log in with your Blocto Wallet and convert one crypto to another on our convenient decentralized exchange.',
        buttonText: 'Start Swapping',
        link: 'https://swap.blocto.app/#/swap',
      },
      {
        title: 'MotoGP',
        icon: motoIcon,
        content:
          'Players build a team to compete in simulated races, choosing a manufacturer and riders.',
        buttonText: 'Learn More',
        link: 'https://motogp-ignition.com/',
      },
      {
        title: 'Mercury',
        icon: mercuryIcon,
        content: 'Uniting partners, fans, and athletes in ways that will change the game.',
        buttonText: 'Learn More',
        link: 'https://mercury.blocksmithlabs.io/',
      },
      {
        title: 'PancakeSwap',
        icon: pancakeSwapIcon,
        content: 'Trade. Earn. Win. NFT. on BNB, Ethereum and Aptos.',
        buttonText: 'Learn More',
        link: 'https://pancakeswap.finance/',
      },
      {
        title: 'Yahoo',
        icon: yahooIcon,
        content: 'Official NFT Marketplace of Yahoo.',
        buttonText: 'Learn More',
        link: 'https://nft-store.blocto.app/yahoo/flow',
      },
      {
        title: 'Topaz',
        icon: topazIcon,
        content:
          'Discover art, culture, & digital expression. The premiere NFT marketplace, built on Aptos.',
        buttonText: 'Learn More',
        link: 'https://www.topaz.so/',
      },
      {
        title: 'Souffl3',
        icon: shouffThreeIcon,
        content: 'The NextGen Smart Trading NFT Marketplace built on Aptos.',
        buttonText: 'Learn More',
        link: 'https://souffl3.com/',
      },
      {
        title: 'AFL Mint',
        icon: aflMintIcon,
        content: 'AFL Mint is the home of officially licensed AFL NFTs.',
        buttonText: 'Learn More',
        link: 'https://www.aflmint.com.au/?utm_source=Flowverse&utm_medium=Website&utm_campaign=Dapp',
      },
      {
        title: 'CRIPCO',
        icon: crpicoIcon,
        content: "CRIPCO, the world's first industry-led DAO NFT platform and a hub for IP 3.0.",
        buttonText: 'Learn More',
        link: 'https://cripco.com/',
      },
      {
        title: 'Emerald City',
        icon: emeraldCityIcon,
        content:
          'Proof of attendance #onFlow. Create events for your communities and prove that they were there.',
        buttonText: 'Learn More',
        link: 'https://floats.city/?utm_source=Flowverse&utm_medium=Website&utm_campaign=Dapp',
      },
      {
        title: 'Blocto IDO',
        icon: idoIcon,
        content:
          'Explore a seamless cross-chain investing IDO platform, the first and only one on Flow.',
        buttonText: 'Learn More',
        link: 'https://swap.blocto.app/#/IDO',
      },
      {
        title: 'Flow Staking',
        icon: flowIcon,
        content:
          'Log in with your Blocto Wallet and convert one crypto to another on our convenient decentralized exchange.',
        buttonText: 'Learn More',
        link: 'https://blocto.app.link/flow-staking',
      },
      {
        title: 'Wormhole',
        icon: wormholeIcon,
        content:
          'Portal is a bridge that offers unlimited transfers across chains for tokens and NFTs wrapped by Wormhole.',
        buttonText: 'Learn More',
        link: 'https://www.portalbridge.com/#/transfer',
      },
      {
        title: 'Celer Network',
        icon: celerIcon,
        content:
          'Celer is a blockchain interoperability protocol enabling a one-click user experience.',
        buttonText: 'Learn More',
        link: 'https://www.celer.network/',
      },
      {
        title: 'Ditto',
        icon: dittoIcon,
        content: 'Ditto is a liquid staking protocol that lets anyone stake their APT tokens.',
        buttonText: 'Learn More',
        link: 'https://www.dittofinance.io/',
      },
      {
        title: 'Tortuga',
        icon: tortugaIcon,
        content:
          'Tortuga is one of the only places you can delegate APT to validators. Stake APT, receive tAPT to use all across the ecosystem.',
        buttonText: 'Learn More',
        link: 'https://tortuga.finance/',
      },
      {
        title: 'Aries Market',
        icon: ariesMarketICon,
        content: 'Aries Markets is a decentralised margin trading protocol on Aptos.',
        buttonText: 'Learn More',
        link: 'https://ariesmarkets.xyz/',
      },
      {
        title: 'Flowverse',
        icon: flowverseIcon,
        content: 'Projects, NFT drops, and ecosystem news on Flow.',
        buttonText: 'Learn More',
        link: 'https://www.flowverse.co/',
      },
      {
        title: 'Flovatar',
        icon: flovatarIcon,
        content:
          'A next-gen NFT platform where people can create characters with any combination they like.',
        buttonText: 'Learn More',
        link: 'https://flovatar.com/',
      },
      {
        title: 'Javascript SDK',
        icon: jsSdkIcon,
        content: 'Blocto Javascript SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/javascript-sdk',
      },
      {
        title: 'iOS SDK',
        icon: iosSdkIcon,
        content: 'Blocto iOS SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/ios-sdk',
      },
      {
        title: 'Android SDK',
        icon: androidSdkIcon,
        content: 'Blocto Android SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/android-sdk',
      },
      {
        title: 'Unity SDK',
        icon: unitySdkIcon,
        content: 'Blocto unity SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/unity-sdk',
      },
      {
        title: 'NFT Listing',
        icon: nftListingIcon,
        content:
          'Your NFT project / collection will be listed on both Blocto app and BloctoBay(NFT marketplace).',
        buttonText: 'Learn More',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLScrr67RLhfJ8bARJHTpp2SztOpQZwmdm7gsDmsqjTlTUUJt7Q/viewform',
      },
      {
        title: 'FT Listing',
        icon: ftListingIcon,
        content: 'Blocto Token Listing Request.',
        buttonText: 'Learn More',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLScBw-nScGdUfEPla_3XgtCGyqIR9sGFJ7GWQvcR6YSiD3Pl3Q/viewform',
      },
    ],
  },
  {
    title: <I18n id="blocto.ecosystem.tab.nft" />,
    children: [
      {
        title: 'MotoGP',
        icon: motoIcon,
        content:
          'Players build a team to compete in simulated races, choosing a manufacturer and riders.',
        buttonText: 'Learn More',
        link: 'https://motogp-ignition.com/',
      },
      {
        title: 'Mercury',
        icon: mercuryIcon,
        content: 'Uniting partners, fans, and athletes in ways that will change the game.',
        buttonText: 'Learn More',
        link: 'https://mercury.blocksmithlabs.io/',
      },
      {
        title: 'Yahoo',
        icon: yahooIcon,
        content: 'Official NFT Marketplace of Yahoo.',
        buttonText: 'Learn More',
        link: 'https://nft-store.blocto.app/yahoo/flow',
      },
      {
        title: 'Topaz',
        icon: topazIcon,
        content:
          'Discover art, culture, & digital expression. The premiere NFT marketplace, built on Aptos.',
        buttonText: 'Learn More',
        link: 'https://www.topaz.so/',
      },
      {
        title: 'Souffl3',
        icon: shouffThreeIcon,
        content: 'The NextGen Smart Trading NFT Marketplace built on Aptos.',
        buttonText: 'Learn More',
        link: 'https://souffl3.com/',
      },
      {
        title: 'AFL Mint',
        icon: aflMintIcon,
        content: 'AFL Mint is the home of officially licensed AFL NFTs.',
        buttonText: 'Learn More',
        link: 'https://www.aflmint.com.au/?utm_source=Flowverse&utm_medium=Website&utm_campaign=Dapp',
      },
      {
        title: 'CRIPCO',
        icon: crpicoIcon,
        content: "CRIPCO, the world's first industry-led DAO NFT platform and a hub for IP 3.0.",
        buttonText: 'Learn More',
        link: 'https://cripco.com/',
      },
      {
        title: 'Emerald City',
        icon: emeraldCityIcon,
        content:
          'Proof of attendance #onFlow. Create events for your communities and prove that they were there.',
        buttonText: 'Learn More',
        link: 'https://floats.city/?utm_source=Flowverse&utm_medium=Website&utm_campaign=Dapp',
      },
      {
        title: 'Flowverse',
        icon: flowverseIcon,
        content: 'Projects, NFT drops, and ecosystem news on Flow.',
        buttonText: 'Learn More',
        link: 'https://www.flowverse.co/',
      },
      {
        title: 'Flovatar',
        icon: flovatarIcon,
        content:
          'A next-gen NFT platform where people can create characters with any combination they like.',
        buttonText: 'Learn More',
        link: 'https://flovatar.com/',
      },
    ],
  },
  {
    title: <I18n id="blocto.ecosystem.tab.defi" />,
    children: [
      {
        title: 'PancakeSwap',
        icon: pancakeSwapIcon,
        content: 'Trade. Earn. Win. NFT. on BNB, Ethereum and Aptos.',
        buttonText: 'Learn More',
        link: 'https://pancakeswap.finance/',
      },
      {
        title: 'Wormhole',
        icon: wormholeIcon,
        content:
          'Portal is a bridge that offers unlimited transfers across chains for tokens and NFTs wrapped by Wormhole.',
        buttonText: 'Learn More',
        link: 'https://www.portalbridge.com/#/transfer',
      },
      {
        title: 'Celer Network',
        icon: celerIcon,
        content:
          'Celer is a blockchain interoperability protocol enabling a one-click user experience.',
        buttonText: 'Learn More',
        link: 'https://www.celer.network/',
      },
      {
        title: 'Ditto',
        icon: dittoIcon,
        content: 'Ditto is a liquid staking protocol that lets anyone stake their APT tokens.',
        buttonText: 'Learn More',
        link: 'https://www.dittofinance.io/',
      },
      {
        title: 'Tortuga',
        icon: tortugaIcon,
        content:
          'Tortuga is one of the only places you can delegate APT to validators. Stake APT, receive tAPT to use all across the ecosystem.',
        buttonText: 'Learn More',
        link: 'https://tortuga.finance/',
      },
      {
        title: 'Aries Market',
        icon: ariesMarketICon,
        content: 'Aries Markets is a decentralised margin trading protocol on Aptos.',
        buttonText: 'Learn More',
        link: 'https://ariesmarkets.xyz/',
      },
    ],
  },
  {
    title: <I18n id="blocto.ecosystem.tab.ecosystem" />,
    children: [
      {
        title: 'BloctoBay',
        icon: bayIcon,
        content:
          'Explore our decentralized NFT marketplace, own your faves, and display your NFT collection—all in one place.',
        buttonText: 'Discover NFTs',
        link: 'https://bay.blocto.app/',
      },
      {
        title: 'BloctoSwap',
        icon: swapIcon,
        content:
          'Log in with your Blocto Wallet and convert one crypto to another on our convenient decentralized exchange.',
        buttonText: 'Start Swapping',
        link: 'https://swap.blocto.app/#/swap',
      },
      {
        title: 'Blocto IDO',
        icon: idoIcon,
        content:
          'Explore a seamless cross-chain investing IDO platform, the first and only one on Flow.',
        buttonText: 'Learn More',
        link: 'https://swap.blocto.app/#/IDO',
      },
      {
        title: 'Flow Staking',
        icon: flowIcon,
        content:
          'Log in with your Blocto Wallet and convert one crypto to another on our convenient decentralized exchange.',
        buttonText: 'Learn More',
        link: 'https://blocto.app.link/flow-staking',
      },
      {
        title: 'Javascript SDK',
        icon: jsSdkIcon,
        content: 'Blocto Javascript SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/javascript-sdk',
      },
      {
        title: 'iOS SDK',
        icon: iosSdkIcon,
        content: 'Blocto iOS SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/ios-sdk',
      },
      {
        title: 'Android SDK',
        icon: androidSdkIcon,
        content: 'Blocto Android SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/android-sdk',
      },
      {
        title: 'Unity SDK',
        icon: unitySdkIcon,
        content: 'Blocto unity SDK is open source and available at Github.',
        buttonText: 'Learn More',
        link: 'https://docs.blocto.app/blocto-sdk/unity-sdk',
      },
      {
        title: 'NFT Listing',
        icon: nftListingIcon,
        content:
          'Your NFT project / collection will be listed on both Blocto app and BloctoBay(NFT marketplace).',
        buttonText: 'Learn More',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLScrr67RLhfJ8bARJHTpp2SztOpQZwmdm7gsDmsqjTlTUUJt7Q/viewform',
      },
      {
        title: 'FT Listing',
        icon: ftListingIcon,
        content: 'Blocto Token Listing Request.',
        buttonText: 'Learn More',
        link: 'https://docs.google.com/forms/d/e/1FAIpQLScBw-nScGdUfEPla_3XgtCGyqIR9sGFJ7GWQvcR6YSiD3Pl3Q/viewform',
      },
    ],
  },
];

export default tabData;
