import React, { useState } from 'react';
import {
  Flex,
  Text,
  InputRightElement,
  Input,
  InputGroup,
  Box,
  useToast,
  CircularProgress,
} from '@chakra-ui/react';
import { useIntl } from 'gatsby-plugin-intl';
import I18n from '../I18n';
import Button from '../button';
import RightArrowIcon from '../svgIcon/rightArrow';
import gtagEvent from '../../utils/gtag';

const SubscribeEmail = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // TODO - submit email change input and button style
  // const [isSubmit, setIsSubmit] = useState(false)

  const toast = useToast();
  const intl = useIntl();

  const isValidEmail = (value) => /\S+@\S+\.\S+/.test(value);

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  // use our own cloud function api
  // doc for this cloud function https://www.notion.so/portto/84194b466336430a8b9a35505d61db34?pvs=4
  const subscribeApi = () => {
    // if (isSubmit) return
    if (!isValidEmail(email)) return;
    const url = 'https://mailchimp-subscribe-kx654nxi2q-uc.a.run.app/';
    const audienceId = '0b696c8bdb';
    setLoading(true);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email_address: email,
        list_id: audienceId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          // setIsSubmit(true)
          window.sessionStorage.setItem('isSubmitEmail', true);
          toast({
            title: 'Thank you for subscribing',
            variant: 'subtle',
            position: 'top',
            isClosable: true,
          });
        } else {
          toast({
            title: res.message,
            variant: 'subtle',
            status: 'error',
            position: 'top',
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast({
          title: err,
          variant: 'subtle',
          status: 'error',
          position: 'top',
          isClosable: true,
        });
      })
      .finally(() => {
        gtagEvent('bloctoweb_click_newsletter', { location: 'integration_block' });
        setLoading(false);
      });
  };

  // TODO - submit email change input and button style
  // useEffect(() => {
  //     const isSubmitStatus = window.sessionStorage.getItem('isSubmitEmail')
  //     if (isSubmitStatus === 'true') {
  //         setIsSubmit(true)
  //     }
  // }, [])

  return (
    <Flex
      flexDirection={{ base: 'column', lg: 'row' }}
      justifyContent={{ lg: 'space-between' }}
      alignItems="center"
      bg="font.highlight"
      w="100%"
      borderRadius="20px"
      p={{ base: '32px', lg: '44px' }}
      justify={{ base: 'center', md: 'space-between' }}
    >
      <Box width={{ base: '100%', lg: 'auto' }} mb={{ base: 'space.4xl', md: 'space.xl', lg: '0' }}>
        <Text
          color="font.inverse"
          fontWeight={{ base: 'weight.m', lg: 'weight.s' }}
          fontSize={{ base: '24px', lg: '39px' }}
          lineHeight="120%"
          mb="space.s"
        >
          <I18n id="blocto.cta.subscribe.title" />
        </Text>
        <Text
          color="font.inverse"
          fontSize={{ base: '14px', lg: '16px' }}
          lineHeight={{ base: '150%', lg: '130%' }}
        >
          <I18n id="blocto.cta.subscribe.content" />
        </Text>
      </Box>

      <Flex
        w={{ base: '100%', lg: '35%' }}
        wrap="wrap"
        align="center"
        justify={{ base: 'center', lg: 'flex-end' }}
      >
        <InputGroup size="md">
          <Input
            height="52px"
            p="12px 12px 12px 24px"
            bg="background.primary"
            border="none"
            borderRadius="140px"
            placeholder={intl.formatMessage({ id: 'blocto.cta.subscribe.placeholder' })}
            _placeholder={{ color: 'font.secondary' }}
            value={email}
            type="email"
            onChange={handleChange}
          />

          <InputRightElement
            width="28px"
            height="28px"
            top="50%"
            right="12px"
            transform="translateY(-50%)"
          >
            {!loading ? (
              <Button
                variant="plain"
                size="sm"
                mode="icon"
                height="28px"
                px="0"
                py="0"
                minWidth="28px"
                onClick={() => {
                  subscribeApi();
                }}
              >
                <RightArrowIcon />
              </Button>
            ) : (
              <CircularProgress
                w="28px"
                h="28px"
                fontSize="28px"
                size="28px"
                isIndeterminate
                color="font.highlight"
              />
            )}
          </InputRightElement>
        </InputGroup>
        <Box w="100%">
          {error && (
            <Text mt="5px" align="center" color="font.inverse" w="100%">
              <I18n id="blocto.cta.subscribe.notValid" />
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default SubscribeEmail;
