import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import { Flex, Text, Image } from '@chakra-ui/react';
import I18n from '../components/I18n';
import Layout from '../components/layout';
import CtaBottom from '../components/ctaBottom';
import BrandCard from '../components/brandCard';
import TabCard from '../components/TabCard';
import { AppContext } from '../context/index';
import bannerImg from '../../static/new_images/ecosystemPage/banner-icon.png';
import bannerImgWebp from '../../static/new_images/ecosystemPage/banner-icon.webp';
import SEO from '../components/seo';
import downloadFnc from '../utils/download';
import gtagEvent from '../utils/gtag';
import SubscribeEmail from '../components/ctaBottom/subscribeEmail';

const CTA_DATA = [
  {
    bg: '#00ADFF',
    title: <I18n id="blocto.cta.integratedWith.title" />,
    content: <I18n id="blocto.cta.integratedWith.content" />,
    buttonText: <I18n id="blocto.cta.integratedWith.button" />,
    onClick: () => {
      gtagEvent('bloctoweb_click_sdkpage', { location: 'integration_block' });
      window.location.href = '/sdk';
    },
  },
  {
    bg: 'icon.text',
    title: <I18n id="blocto.cta.getWallet.title" />,
    content: <I18n id="blocto.cta.getWallet.content" />,
    buttonText: <I18n id="blocto.cta.getWallet.button" />,
    onClick: () => downloadFnc('integration_block'),
  },
];

const EcosystemPage = () => (
  <AppContext.Consumer>
    {(data) => (
      <Layout>
        <SEO
          title="Integrated web3 dApps for crypto & NFT projects | Blocto Ecosystem"
          description="Discover a range of Web3 dApp projects, from DeFi, NFTs, gaming, DAOs, social platforms, and more—all seamlessly integrated with Blocto"
          path="web3-ecosystem"
        />
        <Flex
          bg="interaction.primary"
          w="100%"
          minH={{ base: '621px', sm: '993px', md: '686px' }}
          p={{ base: '116px 32px', sm: '172px 64px', lg: '150px 0' }}
          pb={{ base: '20px', md: '88px', lg: '20px' }}
          m="auto"
          justify={{ base: 'center', lg: 'space-between' }}
        >
          <Flex
            maxW="1200px"
            justify={{ base: 'center', lg: 'space-between' }}
            wrap="wrap"
            m="auto"
            w="100%"
          >
            <Flex w={{ base: '100%', lg: '45%' }} wrap="wrap">
              <Text
                fontSize={{ base: '36px', sm: '3.5rem', md: '73px' }}
                fontWeight={{ base: 600, md: 500 }}
                lineHeight={{ base: '45px', sm: '75px' }}
                color="font.inverse"
                w={{ base: '255px', sm: '355px', md: '400px', lg: '400px' }}
              >
                <I18n id="blocto.ecosystem.title" />
              </Text>
              <Text
                fontSize={{ base: '16px', sm: '21px', md: '20px' }}
                fontWeight={400}
                lineHeight={{ base: '25px', sm: '28px', md: '32px' }}
                color="font.inverse"
                mt={{ base: '10px', md: '20px', lg: '20px' }}
                mb={{ lg: '90px' }}
                w="100%"
                whiteSpace={{ base: 'nowrap', lg: 'normal' }}
              >
                <I18n id="blocto.ecosystem.content" />
              </Text>
            </Flex>
            <Flex
              w={{ base: '100%', lg: '40%' }}
              mt={{ base: '1.5rem', md: '48px', lg: '0' }}
              ml={{ base: '-32px', sm: '0' }}
              justify="center"
            >
              <Image
                minW={{ base: '320px', md: '528px', lg: '568px' }}
                maxW={{ base: '620px' }}
                src={data?.isSupportWebp ? bannerImgWebp : bannerImg}
                h={{ base: 'auto', md: 'auto', lg: 'auto' }}
              />
            </Flex>
          </Flex>
        </Flex>
        <BrandCard isSupportWebp={data?.isSupportWebp} />
        <TabCard />
        <Flex
          w="100%"
          minH={{ base: '621px', sm: '993px', md: '686px' }}
          p={{ base: '116px 32px', sm: '172px 64px', lg: '150px 0' }}
          pb={{ base: '20px', md: '88px', lg: '20px' }}
          m="auto"
          mb={{ base: '88px', md: '132px' }}
          justify={{ base: 'center', lg: 'space-between' }}
        >
          <Flex
            maxW="1200px"
            justify={{ base: 'center', lg: 'space-between' }}
            wrap="wrap"
            m="auto"
            w="100%"
          >
            <CtaBottom data={CTA_DATA} flex="1">
              <SubscribeEmail />
            </CtaBottom>
          </Flex>
        </Flex>
      </Layout>
    )}
  </AppContext.Consumer>
);

EcosystemPage.propTypes = {
  // eslint-disable-next-line
  intl: PropTypes.object.isRequired,
};
export default injectIntl(EcosystemPage);
